import React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Divider,
    Grid,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: { border: 0 },
        margin: theme.spacing(0.5),
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
        { borderLeft: '1px solid transparent', marginLeft: -1 },
}));

const ViewFilterHeader = ({
    handleOnChangeView,
    handleOpenFiltersModal,
    title,
    titleIcon,
    view,
    viewTabs,
}) => {
    const { t } = useTranslation();

    return (
        <HeaderContainer>
            <Grid item xs>
                <TitleIcon icon={titleIcon} title={title} />
            </Grid>

            <Grid display="flex" gap={1} justifyContent="flex-end" item xs>
                {handleOpenFiltersModal && (
                    <>
                        <Tooltip
                            title={t('Filter', { count: 2 })}
                            placement="bottom"
                        >
                            <IconButton onClick={handleOpenFiltersModal}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>

                        <Divider
                            flexItem
                            orientation="vertical"
                            sx={{ mx: 0.5, my: 1 }}
                        />
                    </>
                )}

                <StyledToggleButtonGroup
                    aria-label="view"
                    exclusive
                    onChange={(_, value) => handleOnChangeView(value)}
                    size="small"
                    value={view}
                >
                    {viewTabs.map(({ Icon, label, value }) => (
                        <ToggleButton
                            aria-label={label}
                            key={value}
                            value={value}
                        >
                            <Tooltip title={label} placement="bottom">
                                <Icon />
                            </Tooltip>
                        </ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
            </Grid>
        </HeaderContainer>
    );
};

ViewFilterHeader.propTypes = {
    handleOnChangeView: PropTypes.func,
    handleOpenFiltersModal: PropTypes.func,
    title: PropTypes.string,
    titleIcon: PropTypes.element,
    view: PropTypes.string,
    viewTabs: PropTypes.array,
};

export default ViewFilterHeader;
