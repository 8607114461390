import i18next from 'i18next';
import { isEmpty, toNumber } from 'lodash';

import { createCreditProposalItem } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

import closeAllianceModal from './closeAllianceModal';

export default (proposalId, values) => (dispatch) => {
    const newValues = {
        amount: values.amount,
        commercial_offer: proposalId,
        commission_credit: values.commission_credit,
        commission_credit_amount: values.commission_credit_amount,
        extra_commission: toNumber(values.extra_commission),
        financier_installer: values.financier_installer,
        financier_product: values.financier_product,
        financier_product_insurance: !isEmpty(
            values.financier_product_insurance,
        )
            ? JSON.stringify(values.financier_product_insurance)
            : null,
        hitch_percentage: toNumber(values.hitch_percentage),
        hitch: toNumber(values.hitch),
        insurance: parseFloat(values.insurance),
        is_commission_percentage: values.is_commission_percentage,
        rate: toNumber(values.rate),
        residual: toNumber(values.residual),
        term: parseInt(values.term),
    };

    dispatch(financingAndInsuranceActions[SAVE]());

    createCreditProposalItem(newValues, proposalId)
        .then((response) => {
            dispatch(
                financingAndInsuranceActions[SAVE_SUCCESS](response.data.data),
            );
            dispatch(closeAllianceModal());
            showToast({
                type: 'success',
                body: i18next.t('{{name}} applied correctly', {
                    name: values.name,
                }),
            });
            dispatch(eventsAfterUpdate(['offer-details'], proposalId));
        })
        .catch((error) => {
            dispatch(
                financingAndInsuranceActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
