import { HOURS_IN_DAY, HOURS_IN_YEAR, MS_IN_HOUR } from 'common/constants';
import {
    addDate,
    differenceInDaysDate,
    getUTCDateAsString,
    isDecember31stInInterval,
    isInvalidLeapYearDay,
    subDate,
} from 'common/utils/dates';
import {
    calcEnergyDistributionPercentages,
    formatTierField,
    getDayType,
    getDaysInPeriod,
    getIsGDRate,
    getIsHourlyRate,
    getTierValue,
} from 'common/utils/helpers/rates';

import {
    formatDateDefault,
    formatDateLabel,
    getConsumptionProfileIndexByMS,
    getDefaultSummerMonth,
    getLastMSByStringDate,
    getMaxInitialDate,
    parseDateDefault,
} from '../helpers';
import * as selectors from '../selectors';

import prepareConsumptions from './prepareConsumptions';
import setConsumptionWithCsvDataCalculated from './setConsumptionWithCsvDataCalculated';

const AVGWORKDAYSPERMONTH = 20;
const AVGWORKHOURSPERDAY = 8;

const getAvgDemandBykWh = (kWh) =>
    Math.round(kWh / (AVGWORKDAYSPERMONTH * AVGWORKHOURSPERDAY));

const getFieldsValues = ({
    distribution,
    maxDemand,
    rate,
    totalConsumption, // total consumption in kWh / 1000
    totalPeriodConsumption, // total consumption in the current period without decimals
}) => {
    const fields = { kWh: {}, kW: {} };

    if (!distribution?.length) return { fields, totalPeriodConsumption };

    const isGDRate = getIsGDRate(rate);
    let total = 0; // calculate new total to avoid decimals issues

    // tier.value = percentage between 1 - 100; 100 = totalConsumption
    for (const tier of distribution) {
        const kWhValue = Math.round(tier.value * totalConsumption) || 0;
        const tierKey = tier?.name?.toLowerCase() || '';
        fields.kWh[tierKey] = formatTierField({
            isCertified: rate.isCertified,
            oldField: { placeholder: kWhValue, value: kWhValue },
            tier,
        });

        total += kWhValue;
        if (!isGDRate) continue;
        const kWValue = (maxDemand[tier.identifier] || 0).toFixed(0);

        fields.kW[tierKey] = formatTierField({
            isCertified: rate.isCertified,
            oldField: { placeholder: kWValue, value: kWValue },
            tier,
        });
    }

    return { fields, totalPeriodConsumption: total };
};

const getTotalPeriodConsumption = ({
    consumptionProfileArray,
    demandArray,
    finalIndex = 0,
    initialIndex = 0,
    totalConsumption = 0,
}) => {
    let maxDemand = 0;
    let totalPeriodConsumption = 0;

    for (let index = initialIndex; index < finalIndex; index++) {
        totalPeriodConsumption += consumptionProfileArray[index] || 0;

        if (!demandArray[index]) continue;
        maxDemand = Math.max(demandArray[index], maxDemand);
    }

    return {
        maxDemand,
        totalPeriodConsumption:
            Math.round(totalPeriodConsumption * totalConsumption) || 0,
    };
};

const getFieldsByRateDistribution = ({
    consumptionProfileArray,
    demandArray,
    finalDate,
    initialDate,
    initialIndex,
    rate,
    tiersEnergyDistribution,
    totalConsumption, //total consumption in kWh / 100
}) => {
    if (!tiersEnergyDistribution || !finalDate || !initialDate)
        return { hoursDistribution: {}, fields: {}, totalPeriodConsumption: 0 };

    const distribution = {};
    const hoursDistribution = {};
    const maxDemand = {};
    let hoursCounter = 0;
    let totalPeriodConsumption = 0;

    const tiersNameDictionary = tiersEnergyDistribution?.tiers?.reduce(
        (acc, tier) => {
            if (!tier) return acc;
            acc[tier.identifier] = tier.name;
            return acc;
        },
        {},
    );

    for (
        let date = initialDate;
        date < finalDate;
        date = addDate(date, { hours: 1 })
    ) {
        const day = date.getUTCDate();
        const hour = date.getUTCHours();
        const month = date.getUTCMonth();
        const year = date.getUTCFullYear();

        if (isInvalidLeapYearDay({ day, month, year })) continue;

        const dayType = date.getUTCDay();
        const dayTypeText = getDayType(dayType);

        const tier = getTierValue({
            day: dayType,
            hour,
            month,
            tiers_distribution: tiersEnergyDistribution,
        });

        // Create hours distribution
        if (!hoursDistribution[tier]) hoursDistribution[tier] = {};
        if (!hoursDistribution[tier][dayTypeText])
            hoursDistribution[tier][dayTypeText] = {};
        if (!hoursDistribution[tier][dayTypeText][hour])
            hoursDistribution[tier][dayTypeText][hour] = 0;
        hoursDistribution[tier][dayTypeText][hour]++;

        if (!hoursDistribution[tier].total) hoursDistribution[tier].total = 0;
        hoursDistribution[tier].total++;

        const realIndex = initialIndex + hoursCounter;

        const consumption = consumptionProfileArray[realIndex] || 0;
        distribution[tier] = {
            identifier: tier,
            name: tiersNameDictionary[tier],
            value: (distribution[tier]?.value || 0) + consumption,
        };

        hoursCounter++;

        if (!consumption) continue;
        totalPeriodConsumption += consumption;

        const demand = demandArray[realIndex] || 0;
        if (!demand) continue;
        maxDemand[tier] = Math.max(demand, maxDemand[tier] || 0);
    }

    const { fields, totalPeriodConsumption: fixedTotalPeriodConsumption } =
        getFieldsValues({
            distribution: Object.values(distribution),
            maxDemand,
            rate,
            totalConsumption,
            totalPeriodConsumption:
                Math.round(totalPeriodConsumption * totalConsumption) || 0,
        });

    return {
        fields,
        hoursDistribution,
        totalPeriodConsumption: fixedTotalPeriodConsumption,
    };
};

const getFieldsDistribution = ({
    consumptionProfileArray,
    demandArray,
    finalDate,
    finalIndex,
    initialDate,
    initialIndex,
    rate,
    rateConfiguration,
    totalConsumption,
}) => {
    const isHourlyRate = getIsHourlyRate(rate);
    if (
        !rateConfiguration?.tiers_energy_distribution?.length ||
        !isHourlyRate
    ) {
        const { maxDemand, totalPeriodConsumption } = getTotalPeriodConsumption(
            {
                consumptionProfileArray,
                demandArray,
                finalIndex,
                initialIndex,
                totalConsumption,
            },
        );
        const avgDemand =
            maxDemand || getAvgDemandBykWh(totalPeriodConsumption) || 0;
        return {
            hoursDistribution: {},
            fields: {
                kW: {
                    peak: {
                        label: 'kW',
                        placeholder: avgDemand.toString(),
                        value: avgDemand,
                    },
                },
            },
            totalPeriodConsumption,
        };
    }
    return getFieldsByRateDistribution({
        consumptionProfileArray,
        demandArray,
        finalDate,
        initialDate,
        initialIndex,
        rate,
        tiersEnergyDistribution: rateConfiguration.tiers_energy_distribution[0],
        totalConsumption,
    });
};

const getMaxDate = () => formatDateDefault(getMaxInitialDate());

export default ({ formValues, rate, rateConfiguration }) =>
    (dispatch, getState) => {
        const state = getState();
        const {
            consumptionProfileArray,
            data: csvData,
            demandArray,
        } = selectors.getConsumptionProfileCsv(state);
        const summerMonths = selectors.getMonthsForSelect(state);

        const {
            lastDateConsumption = getMaxDate(),
            modified = false,
            totalConsumption = 0,
        } = csvData || {};

        const periodicityType = !rate.isCertified
            ? rate.periodicityType
            : Number.parseInt(formValues.is_bimonthly);
        const monthsPerPeriod = periodicityType + 1;
        const minDaysPerPeriod = monthsPerPeriod * 15;
        const quantityMonths = 12 / monthsPerPeriod;

        const lastDateConsumptionUTC =
            getLastMSByStringDate(lastDateConsumption);

        let firstDateConsumptionUTC =
            lastDateConsumptionUTC - MS_IN_HOUR * HOURS_IN_YEAR;

        if (
            isDecember31stInInterval(
                firstDateConsumptionUTC,
                lastDateConsumptionUTC,
            )
        )
            firstDateConsumptionUTC -= MS_IN_HOUR * HOURS_IN_DAY;

        const formattedFirstDateConsumption = getUTCDateAsString(
            firstDateConsumptionUTC,
        );

        let remainingConsumption = totalConsumption;

        const { summary } = [...new Array(quantityMonths)].reduce(
            (acc, _, index) => {
                const isLastElement = index === quantityMonths - 1;

                const final = acc.currentPivotDate;
                const finalDate = formatDateDefault(final);

                const initialMonth = final.getMonth() - (monthsPerPeriod - 1);
                const initial = new Date(final.getFullYear(), initialMonth, 0);
                if (differenceInDaysDate(final, initial) < minDaysPerPeriod)
                    initial.setDate(0);

                const initialDate = isLastElement
                    ? formattedFirstDateConsumption
                    : formatDateDefault(initial);

                const label = formatDateLabel(
                    subDate(final, { days: minDaysPerPeriod }),
                );

                // Add 1 ms because the interval is (initial, final]
                const initUTCDate = getLastMSByStringDate(initialDate) + 1;
                const finalUTCDate = getLastMSByStringDate(finalDate) + 1;

                const initialIndex = getConsumptionProfileIndexByMS({
                    currentMS: initUTCDate,
                    finalMS: lastDateConsumptionUTC,
                });
                const finalIndex = getConsumptionProfileIndexByMS({
                    currentMS: finalUTCDate,
                    finalMS: lastDateConsumptionUTC,
                });

                const { hoursDistribution, fields, totalPeriodConsumption } =
                    getFieldsDistribution({
                        consumptionProfileArray,
                        demandArray,
                        distribution: formValues.distribution,
                        finalDate: new Date(finalUTCDate),
                        finalIndex,
                        initialDate: new Date(initUTCDate),
                        initialIndex,
                        rate,
                        rateConfiguration,
                        totalConsumption: totalConsumption / 100, // Reduce operations = better performance
                    });

                // To avoid decimal issues
                const fixedTotalPeriodConsumption = isLastElement
                    ? Math.max(remainingConsumption, 0)
                    : totalPeriodConsumption;

                remainingConsumption -= fixedTotalPeriodConsumption;

                const dailyAvg =
                    (fixedTotalPeriodConsumption *
                        (HOURS_IN_DAY * MS_IN_HOUR)) /
                    (finalUTCDate - initUTCDate);

                const period = {
                    ...fields,
                    dailyAvg: { placeholder: '0', value: dailyAvg.toFixed(2) },
                    daysInPeriod: getDaysInPeriod({
                        final_date: finalDate,
                        initial_date: initialDate,
                    }),
                    file: null,
                    final_date: finalDate,
                    hoursDistribution,
                    initial_date: initialDate,
                    label,
                    power_factor: 90,
                    total: {
                        placeholder: '0',
                        value: fixedTotalPeriodConsumption,
                    },
                };

                acc.currentPivotDate = initial;
                acc.summary.push(period);

                return acc;
            },
            {
                currentPivotDate: parseDateDefault(lastDateConsumption),
                summary: [],
            },
        );

        const defaultSummerMonth = getDefaultSummerMonth({
            formValues,
            selectedRate: rate,
            summerMonths,
        });

        const distribution = calcEnergyDistributionPercentages(summary);

        const newValues = {
            ...formValues,
            cnmc_data_modified: false,
            csv_data_modified: modified,
            distribution,
            last_consumption: lastDateConsumption,
            periodicity_type: periodicityType,
            rate_division_summer: defaultSummerMonth,
            receipt_origin: 'manual',
            summary,
        };

        if (!rate.name)
            newValues.is_bimonthly = periodicityType === 1 ? '1' : '0';

        dispatch(prepareConsumptions({ initialValues: newValues }));
        dispatch(setConsumptionWithCsvDataCalculated(true));
    };
