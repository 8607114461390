import get from 'lodash/get';

import { INITIAL_VALUES_CEIDE } from '../actionTypes';
import { percentageToValue, valueToPercentage } from '../helpers';
import { financingAndInsuranceActions } from '../reducer';

export default (values) => (dispatch) => {
    const parameters = get(values, 'financier_product_parameters', {});
    const infonavitData = get(values, 'infonavit_data', {});

    const newValues = {
        administratives_expenses_percentage:
            infonavitData.administratives_expenses_percentage,
        administratives_expenses: parameters.administratives_expenses,
        amount: values.total,
        commission_credit: valueToPercentage(
            parameters.is_commission_percentage,
            parameters.commission_credit,
            values.total,
        ),
        commission_credit_amount: parameters.is_commission_percentage
            ? percentageToValue(
                  parameters.is_commission_percentage,
                  parameters.commission_credit,
                  values.total
              )
            : parameters.commission_credit,
        financier_installer: values.financier_installer,
        financier_product: parameters.financier_product,
        hitch_percentage: valueToPercentage(
            parameters.is_percentage,
            parameters.hitch,
            values.total,
        ),
        hitch: percentageToValue(
            parameters.is_percentage,
            parameters.hitch,
            values.total,
        ),
        infonavit_data: values.infonavit_data,
        insurance: parameters.insurance,
        is_commission_percentage: parameters.is_commission_percentage,
        is_percentage: parameters.is_percentage,
        rate: parameters.rate,
        retention_percentage: infonavitData.retention_percentage,
        retention: infonavitData.retention_value,
        total: values.total,
        types_business_company: values.types_business_company,
    };

    dispatch(financingAndInsuranceActions[INITIAL_VALUES_CEIDE](newValues));
};
