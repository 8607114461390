import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from 'sunwise-ui';

const InverterDetails = ({ errorConfig, inverterItem }) => {
    const { t } = useTranslation();
    return (
        <>
            <FormHelperText>
                {t('Panel', { count: 2 })}: {t('Min')} {inverterItem.min_panels}{' '}
                -{t('Max')} {inverterItem.max_panels}{' '}
                {`${t('Ratio ACDC')}: ${inverterItem.oversize}%`}
            </FormHelperText>

            {!inverterItem.is_compatible && errorConfig && (
                <FormHelperText>
                    <strong>{t(errorConfig.label)}. </strong>
                </FormHelperText>
            )}
        </>
    );
};

InverterDetails.propTypes = {
    errorConfig: PropTypes.object,
    inverterItem: PropTypes.object,
};

export default InverterDetails;
