import React, { useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import ListIcon from '@mui/icons-material/List';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer } from 'sunwise-ui';

import ViewFilterHeader from 'common/components/ViewFilterHeader';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import AccessRestrictedBanner from '../afterSalesSettings/components/warnings/AccessRestrictedBanner';

import AppliedFilters from './components/AppliedFilters';
import Content from './components/Content';
import FiltersModal from './components/FiltersModal';
import { VIEWS } from './constants';

const Container = ({ initialValuesFilters, setIsOpenFiltersModal }) => {
    const [view, setView] = useState(VIEWS.LIST);
    const { t } = useTranslation();
    const countryCurrencyLocale = getCountryCurrencyLocale();

    const tabButtons = [
        { Icon: ListIcon, label: t('List'), value: VIEWS.LIST },
        { Icon: TableChartIcon, label: t('Funnel'), value: VIEWS.FUNNEL },
    ];

    const handleOnChangeView = (newView) => {
        if (!newView) return;
        setView(newView);
    };

    const handleOpenFiltersModal = () => setIsOpenFiltersModal(true);

    return (
        <AccessRestrictedBanner>
            <MaterialContainer maxWidth={false} height="100%">
                <ViewFilterHeader
                    handleOnChangeView={handleOnChangeView}
                    handleOpenFiltersModal={handleOpenFiltersModal}
                    title={t('Report funnel')}
                    titleIcon={<ArticleIcon color="primary" fontSize="large" />}
                    view={view}
                    viewTabs={tabButtons}
                />

                <AppliedFilters filtersValues={initialValuesFilters} />

                <Content
                    countryCurrencyLocale={countryCurrencyLocale}
                    //   formattedReads={formattedReads}
                    freq={initialValuesFilters?.freq}
                    // setFormattedReads={setFormattedReads}
                    sx={{ mt: 2 }}
                    view={view}
                />

                <FiltersModal />
            </MaterialContainer>
        </AccessRestrictedBanner>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = () => ({});

Container.propTypes = {
    initialValuesFilters: PropTypes.object,
    setIsOpenFiltersModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION),
    withRouter
)(Container);
