import get from 'lodash/get';

import { getDecimals } from 'common/utils/helpers';

import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

export default (values, nextRateData) => (dispatch) => {
    const discount = get(values, 'discount', 0);
    const priceWatt = get(values, 'price_watt', 0);
    const priceTotal = get(values, 'discount_per_unit', 0);
    const considerations = get(values, 'considerations', 'null');

    const newValues = {
        apply_desired_power_factor: values.apply_desired_power_factor,
        bag_years: values.bag_years,
        considerations,
        currency_abbreviation: get(values, 'currency.abbreviation', null),
        currency: get(values, 'currency.id', null),
        dap_value: values.dap_value,
        degradation_percentage: values.degradation_percentage,
        deprecated: values.deprecated,
        desired_power_factor: get(values, 'desired_power_factor', 90),
        discount_rate: values.discount_rate,
        discount: discount.toFixed(getDecimals(discount)),
        energy_export_price: values.energy_export_price || 0,
        generation_delay: values.generation_delay,
        guaranteed_gen: values.guaranteed_gen,
        guaranteed_gen_active: values.guaranteed_gen_active || false,
        has_low_tension_concept: values.has_low_tension_concept || false,
        id: values.id,
        increase_percentage: values.increase_percentage,
        isr_percentage: values.isr_percentage || 30,
        isr_simulation_type: values.isr_simulation_type,
        isr_simulation_annual: values.isr_simulation,
        isr_simulation_monthly: values.isr_simulation,
        isr: values.isr,
        netted_exported_generation_price:
            values.netted_exported_generation_price || 0,
        opex_percentage_increase: values.opex_percentage || 0,
        opex_periodicity: values.opex_periodicity,
        opex: values.opex,
        other_concepts: get(nextRateData, 'other_concepts', []),
        ppa_active: values.ppa_active || false,
        ppa_percentage_increase: values.ppa_percentage_increase || 0,
        ppa_price: values.ppa_price || 0,
        price_total: priceTotal.toFixed(getDecimals(priceTotal)),
        price_watt: priceWatt.toFixed(getDecimals(priceWatt)),
        proposal_plan: values.proposal_plan,
        tax_rate_percentage: values.tax_rate_percentage,
        taxes_concepts: get(nextRateData, 'taxes_concepts', []),
        type_change_offer: values.type_change_offer,
        type_change: values.type_change,
        type_discount: values.type_discount,
        zero_export: values.zero_export || false,
    };

    dispatch(proposalGeneratorConfigModalActions[INITIAL_VALUES](newValues));
};
