export const NAME = 'funnel-reports';

export const VIEWS = {
    LIST: 'list',
    FUNNEL: 'funnel',
};

export const FILTERS_KEYS = {
    AGENT: 'agent_id',
    CONTACT_ID: 'contact_id',
    ELECTRIC_BILL_STATUS: 'electric_bill_status',
    FINAL_DATE: 'final_date',
    INITIAL_DATE: 'initial_date',
    PERIOD: 'period',
    POLITICAL_DIVISION: 'political_division1_id',
    PROJECT_ID: 'sunwise_project_id',
    PROJECT_STATUS: 'sw_project_status_id',
    PROVIDER: 'provider',
    RATE_ID: 'rate_id',
    REPORT_STATUS: 'report_status',
    ZIP_CODE: 'zip_code',
};

export const FILTERS_TO_DISPLAY = [
    FILTERS_KEYS.AGENT,
    FILTERS_KEYS.CONTACT_ID,
    FILTERS_KEYS.PROJECT_STATUS,
    FILTERS_KEYS.PROJECT_ID,
    FILTERS_KEYS.RATE_ID,
    FILTERS_KEYS.POLITICAL_DIVISION,
    FILTERS_KEYS.ZIP_CODE,
    FILTERS_KEYS.REPORT_STATUS,
    FILTERS_KEYS.ELECTRIC_BILL_STATUS,
    FILTERS_KEYS.PROVIDER,
    FILTERS_KEYS.PERIOD,
];

export const STATUS_KEYS = {
    ERROR: 2,
    UPDATED: 0,
    PENDING: 1,
};

export const STATUS_CONFIG = {
    [STATUS_KEYS.PENDING]: { color: '#fdcb6e', label: 'Pending' },
    [STATUS_KEYS.UPDATED]: { color: '#00b894', label: 'Up to date' },
    [STATUS_KEYS.ERROR]: { color: '#d63031', label: 'Error' },
};

export const REPORT_TYPES = {
    SINGLE_PERIOD: 0,
    MULTIPERIOD: 1,
    GROUPED_PERIODS: 2,
};

export const REPORT_TYPES_CONFIG = {
    [REPORT_TYPES.SINGLE_PERIOD]: { label: 'Single period' },
    [REPORT_TYPES.MULTIPERIOD]: { label: 'Multiperiod' },
    [REPORT_TYPES.GROUPED_PERIODS]: { label: 'Grouped periods' },
};
