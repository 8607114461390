import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FiltersForm from 'common/components/FiltersForm';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import * as credentialIntegrationsSelectors from '../../../credentialIntegrations/selectors';
import * as actions from '../../actions';
import { FILTERS_KEYS, RATE_ID_FILTERS } from '../../constants';
import { getFilteredSelectOptions } from '../../helpers';
import * as selectors from '../../selectors';

import StatusSelect from './StatusSelect';

const FormContainer = ({
    agentsForSelect,
    changeFilters,
    contactFilters,
    handleClose,
    initialValues,
    isLoadingContacts,
    isLoadingPoliticalDivisions,
    isLoadingRates,
    politicalDivisionForSelect,
    projectFilters,
    providersForSelect,
    ratesDictionary,
}) => {
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredProjectStatuses, setFilteredProjectStatuses] = useState([]);
    const [filteredRates, setFilteredRates] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const { t } = useTranslation();
    const isMexicanAccount = getIsMexicanAccount();
    const politicalDivisionLabel = isMexicanAccount
        ? t('State')
        : t('Political division of order 1');

    const handleFilterProjectOptions = ({
        agentId,
        contactId,
        projectStatusId,
        rateId,
    }) => {
        const {
            contacts,
            projectStatuses,
            projects: newProjects,
            rates,
        } = getFilteredSelectOptions({
            agentId,
            contactId,
            contacts: contactFilters,
            projectStatusId,
            projects: projectFilters,
            rateId,
        });
        setFilteredContacts(contacts);
        setFilteredProjectStatuses(projectStatuses);
        setFilteredProjects(newProjects);
        setFilteredRates(rates);
    };

    useEffect(() => {
        handleFilterProjectOptions({
            agentId: initialValues?.[FILTERS_KEYS.AGENT],
            contactId: initialValues?.[FILTERS_KEYS.CONTACT_ID],
            projectStatusId: initialValues?.[FILTERS_KEYS.PROJECT_STATUS],
            rateId: initialValues?.[FILTERS_KEYS.RATE_ID],
        });
    }, [projectFilters, initialValues]);

    const onSubmit = (data) => {
        const rateId = data[FILTERS_KEYS.RATE_ID];
        const rateData = ratesDictionary[rateId];

        const newData = { ...data };

        if (!rateData?.is_certified) {
            delete newData[RATE_ID_FILTERS.CERTIFIED];
            newData[RATE_ID_FILTERS.NOT_CERTIFIED] = rateId;
        } else {
            delete newData[RATE_ID_FILTERS.NOT_CERTIFIED];
            newData[RATE_ID_FILTERS.CERTIFIED] = rateId;
        }

        changeFilters(newData);
        handleClose();
    };

    return (
        <FiltersForm
            filters={[
                {
                    label: t('Project'),
                    fields: [
                        {
                            disabled: isLoadingContacts,
                            label: t('Agent'),
                            name: FILTERS_KEYS.AGENT,
                            onChange: ({ setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: value,
                                });
                                setValue(FILTERS_KEYS.CONTACT_ID, null);
                                setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                            },
                            options: agentsForSelect,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Contact'),
                            name: FILTERS_KEYS.CONTACT_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                            },
                            options: filteredContacts,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Project status'),
                            name: FILTERS_KEYS.PROJECT_STATUS,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: getValues(
                                        FILTERS_KEYS.CONTACT_ID
                                    ),
                                    projectStatusId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                            },
                            options: filteredProjectStatuses,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingRates,
                            label: t('Rate'),
                            name: FILTERS_KEYS.RATE_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: getValues(
                                        FILTERS_KEYS.CONTACT_ID
                                    ),
                                    projectStatusId: getValues(
                                        FILTERS_KEYS.PROJECT_STATUS
                                    ),
                                    rateId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                            },
                            options: filteredRates,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Project'),
                            name: FILTERS_KEYS.PROJECT_ID,
                            options: filteredProjects,
                            type: 'select',
                        },
                    ],
                },
                {
                    label: t('Location'),
                    fields: [
                        {
                            disabled: isLoadingPoliticalDivisions,
                            label: politicalDivisionLabel,
                            name: FILTERS_KEYS.POLITICAL_DIVISION,
                            options: politicalDivisionForSelect,
                            type: 'select',
                        },
                        {
                            label: t('Zip code'),
                            name: FILTERS_KEYS.ZIP_CODE,
                            type: 'input',
                        },
                    ],
                },
                {
                    label: t('System', { count: 2 }),
                    fields: [
                        {
                            render: ({ control }) => (
                                <StatusSelect
                                    control={control}
                                    label={t('Status')}
                                    name={FILTERS_KEYS.STATUS}
                                    variant="standard"
                                />
                            ),
                            label: t('Status'),
                            name: FILTERS_KEYS.STATUS,
                            type: 'custom',
                        },
                        {
                            label: t('Provider'),
                            name: FILTERS_KEYS.PROVIDER,
                            options: [
                                { label: '', options: providersForSelect },
                            ],
                            type: 'select',
                        },
                    ],
                },
            ]}
            initialValues={initialValues}
            onCancel={handleClose}
            onSubmit={onSubmit}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    agentsForSelect: selectors.getAgentsForSelect,
    contactFilters: selectors.getContacts,
    initialValues: selectors.getInitialValuesFilters,
    isLoadingContacts: selectors.getContactsIsLoading,
    isLoadingPoliticalDivisions: selectors.getPoliticalDivisionIsLoading,
    isLoadingRates: selectors.getRatesIsLoading,
    politicalDivisionForSelect: selectors.getPoliticalDivisionForSelect,
    projectFilters: selectors.getProjects,
    providersForSelect: credentialIntegrationsSelectors.getProvidersForSelect,
    ratesDictionary: selectors.getRatesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    changeFilters: (filters) => dispatch(actions.changeFilters(filters)),
});

FormContainer.propTypes = {
    agentsForSelect: PropTypes.array,
    changeFilters: PropTypes.func,
    contactFilters: PropTypes.array,
    handleClose: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingContacts: PropTypes.bool,
    isLoadingPoliticalDivisions: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    politicalDivisionForSelect: PropTypes.array,
    projectFilters: PropTypes.array,
    providersForSelect: PropTypes.array,
    ratesDictionary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
