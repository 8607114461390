import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Chip, DataGrid, Typography } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import { REPORT_TYPES_CONFIG } from '../constants';
import { getReportPeriods } from '../helpers';

const getRowStyles = (selectable) => {
    const styles = { '& .MuiDataGrid-cell:focus': { outline: 'none' } };
    if (selectable) styles['& .MuiDataGrid-row'] = { cursor: 'pointer' };
    return styles;
};

const ApiSourceList = ({ isFetchingPowerStations, reports }) => {
    const { t } = useTranslation();
    const localeDateFormat = getDateFormatByLocale();

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            minWidth: 200,
            sortable: false,
            valueGetter: ({ row }) => row?.name || '--',
        },
        {
            align: 'left',
            field: 'period',
            flex: 1,
            headerName: t('Period', { count: 2 }),
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => {
                if (!row?.groups?.length) return null;

                const periods = getReportPeriods(row.groups, localeDateFormat);

                return periods.map((date, i) => (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        key={date + i}
                    >
                        <CalendarTodayIcon fontSize="small" />
                        <Typography variant="body2">{date}</Typography>
                    </Box>
                ));
            },
        },
        {
            align: 'left',
            field: 'type',
            flex: 1,
            headerName: t('Type'),
            minWidth: 200,
            renderCell: ({ row }) => {
                const config = REPORT_TYPES_CONFIG[row?.type];

                if (!config?.label) return null;

                return <Chip label={t(config.label)} />;
            },
            sortable: false,
        },
        {
            align: 'left',
            field: 'electric_bill',
            flex: 1,
            headerName: t('Electric bill'),
            minWidth: 200,
            renderCell: ({ row }) => {
                if (!row?.electric_bill?.last_update) return null;

                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <Typography variant="body2">
                            {row?.electric_bill?.last_update}
                        </Typography>
                    </Box>
                );
            },
            sortable: false,
        },
        {
            align: 'left',
            field: 'project',
            flex: 1,
            headerName: t('Project'),
            minWidth: 200,
            sortable: false,
            valueGetter: ({ row }) => row?.project?.name || '--',
        },
    ];

    return (
        <DataGrid
            autoHeight
            checkboxSelection={false}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{
                pagination: { rowsPerPageOptions: [15, 25, 50, 75, 100] },
            }}
            columns={columns || []}
            disableColumnMenu
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            hideFooterSelectedRowCount
            loading={isFetchingPowerStations}
            initialState={{ pagination: { pageSize: 15 } }}
            onPageChange={() => {}}
            onPageSizeChange={() => {}}
            onRowClick={() => {}}
            page={undefined}
            pageSize={15}
            pagination
            paginationMode="client"
            rowCount={undefined}
            rows={reports}
            selectionModel={[]}
            sx={getRowStyles(true)}
        />
    );
};

ApiSourceList.propTypes = {
    isFetchingPowerStations: PropTypes.bool,
    reports: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceList);
