import { transformDateFormat } from 'common/utils/dates';

export const getDateRangeLabel = (initialDate, finalDate) => {
    if (!initialDate && !finalDate) return null;
    return `${initialDate} - ${finalDate}`;
};

export const getFilterNameFromOptions = (id, options) => {
    if (!id || !options?.length) return;

    for (const option of options) {
        const item = option?.options?.find((item) => item.value === id);
        if (item) return item?.label;
    }

    return null;
};

export const getReportPeriods = (groups, localeDateFormat) => {
    if (!groups?.length) return [];

    const dates = [];

    for (const group of groups) {
        if (!group?.periods?.length) continue;

        for (const period of group.periods) {
            if (!period) continue;

            const initialDate = transformDateFormat(
                period.initial_date,
                localeDateFormat,
                'dd/MM/yyyy'
            );

            const finalDate = transformDateFormat(
                period.final_date,
                localeDateFormat,
                'dd/MM/yyyy'
            );

            dates.push(`${initialDate || ''} - ${finalDate || ''}`);
        }
    }

    return dates;
};
