import React from 'react';

import { REPORT_TYPES } from '../constants';

import ReportTable from './ReportTable';

const mock = [
    {
        id: 1,
        name: 'test',
        groups: [
            {
                periods: [
                    { initial_date: '01/01/2024', final_date: '01/02/2024' },
                ],
            },
        ],
        type: REPORT_TYPES.SINGLE_PERIOD,
        electric_bill: { url: 'ENE 2024', last_update: '01/01/2024 10:55' },
        project: { name: 'Test project' },
    },
];

const Content = () => {
    return <ReportTable reports={mock} />;
};

export default Content;
